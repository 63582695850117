import {client} from "../utils/axios";

export type Unit = {
    id: number;
    name: string;
    employee_id?: number;
    division_id: number;
};

export const getUnits = async () => {
    try {
        return await client
            .get<Unit[]>("units",)
            .then(res => {
                return res.data
            });
    } catch (err) {}
};

export const getAllUnitsByDivisionID = async (param: number) => {
    try {
        return await client
            .get<Unit[]>(`division/${param.toString().trim()}/units`,)
            .then(res => res.data);
    } catch (err) {}
};

// export const getAllUnitsByDivisionID = async (param: number) => {
//     try {
//         return await axios
//             .get<Unit[]>(API_URL + `division/${param.toString().trim()}/units`, {
//                 headers: {
//                     "X-API-KEY": X_API_KEY
//                 }
//             })
//             .then(res => res.data);
//     } catch (err) {}
// };

export const getUnit = async (param: number) => {
    try {
        return await client
            .get<Unit>("units/" + param.toString().trim())
            .then(res => res.data);
    } catch (err) {}
};
