import axios from "axios";
import {wrapper} from "axios-cookiejar-support";
import {CookieJar} from "tough-cookie";
import {API_URL, X_API_KEY} from "./api";

wrapper(axios);

const cookieJar = new CookieJar();
export const client = axios.create({
    jar: cookieJar,
    withCredentials: true,
    baseURL: API_URL,
    headers: {
        // "X-Frame-Options": `ALLOW-FROM ${API_URL} `,
        'X-API-KEY': X_API_KEY,
    },
})