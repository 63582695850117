import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {EmployeeDB} from "./employee";
import {Department} from "./departmentService";
import {client} from "../utils/axios";

export type SubDepartment = {
    id: number;
    name: string;
    department_id: number
    employee_id: {
        Int64: number;
        Valid: boolean
    };
    SeniorManager: EmployeeDB;
    Department: Department;
};

export const getSubDepartments = async () => {
    try {
        // console.log(data)
        return await client
            .get<SubDepartment[]>("sub_departments")
            .then(res => res.data);
    } catch (err) {}
};


export const getSubDepartment = async (param: number) => {
    try {
        return await client
            .get<SubDepartment>("sub_departments/" + param.toString().trim())
            .then(res => res.data);
    } catch (err) {}
};


export const getSubDepartmentByDepartmentID = async (param: number) => {
    try {
        return await client
            .get<SubDepartment[]>(`department/${param.toString().trim()}/sub_departments` )
            .then(res => res.data);
    } catch (err) {}
};
