import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Login from "./layouts/Login";
import Dashboard from "./layouts/Dashboard";
import Logout from "./layouts/Logout";
import SignUp from "./layouts/SignUp";
import ForgotPassword from "./layouts/ForgotPassword";
import ChangePassword from "./layouts/ChangePassword";
import AnalyticPage from "./components/Analytics/analyticPage";
import Appraisal from "./components/Appraisal/appraisal";
import AppraisalModelTabProvider from "./context/appraisal/appraisalModelTabContext";

function App() {
  return (

      <Routes>
        <Route
            path={"/"}
            element={<Login />}
        />
          <Route
            path={"/forgot_password"}
            element={<ForgotPassword />}
        />
          <Route
            path={"/changePassword/:token"}
            element={<ChangePassword />}
        />
        <Route
            path={"/login"}
            element={<Login />}
        />

        {/*<Route*/}
        {/*    path={"/signup"}*/}
        {/*    element={<SignUp />}*/}
        {/*/>*/}

        <Route
            path={"/dashboard"}
            element={<Dashboard />}
        />
          <Route
            path={"/analytics"}
            element={<AnalyticPage />}
        />

        {/*  <Route*/}
        {/*    path={"/appraisal/:user_id"}*/}
        {/*    element={*/}
        {/*        <AppraisalModelTabProvider>*/}
        {/*            <Appraisal />*/}
        {/*        </AppraisalModelTabProvider>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
            path={"/logout"}
            element={<Logout />}
        />

          <Route
            path={"*"}
            element={<div>Page Not Found</div>}
        />
      </Routes>
  );
}

export default App;
