import React, {useEffect} from 'react';
import {IoPersonCircle} from "react-icons/io5";
import {useButtonTypeContext} from "../../context/button/buttonContext";
import {ButtonType} from "../../context/button/buttonTypes";
import {
    generateWorkPlan2Pdf,
    generateWorkPlanPdf,
    getWorkPlanByEmployeeID,
    Header,
    TableDataFormat,
    TableFooter,
    WorkPlanData
} from "../../services/workPlanServiice";
import {workPlanPdfGenFormat, workPlanReStruct} from "../../utils/workPlanRestruct";
import {useAuth} from "../../context/authContext";
import {useNavigate} from "react-router-dom";
import {useWorkPlanToReportGen} from "../../context/workPlanToReport/workPlanToReportGenContext";
import { BsFileEarmarkPdf} from "react-icons/bs";
import {TbFileIsr} from "react-icons/tb";
import PdfRender from "../comps/pdfRender";
import {PDF_HOST} from "../../utils/api";
import {RiArrowGoBackLine} from "react-icons/ri";
import {FcApproval, FcCancel} from "react-icons/fc";
import {PiDotsThree} from "react-icons/pi";

const EmployeeWorkPlan = () => {
    const [workPlans, setWorkPlans] = React.useState<WorkPlanData[]>([]);
    const [selectedWorkPlan, setSelectedWorkPlan] = React.useState<WorkPlanData|undefined>(undefined);
    const {employeeI} = useAuth();
    const {setWorkPlanI} = useWorkPlanToReportGen();
    const navigate = useNavigate();
    const [refresh, setRefresh] = React.useState(false);
    const [displayPdf, setDisplayPdf] = React.useState(false);


    useEffect(() => {
        if (!employeeI) navigate("/login")
        setWorkPlanI(undefined)
        fetchData().then();
    },[refresh]);

    const handleChangeWorkPlan = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value);
        const workPlan = workPlans.find((workPlan) => workPlan.id === value);
        if (!workPlan) return;
        setSelectedWorkPlan(workPlan);
    }

    const fetchData = async () => {
        if (!employeeI) {
            navigate("/login");
            return;
        }
        await getWorkPlanByEmployeeID(employeeI.user_id).then(res => {
            if (res) {
                setWorkPlans(
                    res.filter(rr => {
                        if (rr.employee_id !== employeeI.user_id) {
                            return rr.is_draft === false;

                        }
                        return true
                    }).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                );
                setSelectedWorkPlan(
                    res.filter(rr => {
                        if (rr.employee_id !== employeeI.user_id) {
                            return rr.is_draft === false;

                        }
                        return true
                    }).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0]
                );
                // console.log(workPlanReStruct(res.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0].Tasks))
            }
        });
    }

    const handleGenerateWorkPlan =async (e: React.MouseEvent<HTMLDivElement>) => {
        if (!selectedWorkPlan) return;
        const header: Header = {
            owner: `${selectedWorkPlan.Employee.fname.toUpperCase()} ${selectedWorkPlan.Employee.mname.Valid ? selectedWorkPlan.Employee.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Employee.lname.toUpperCase()}`,
            department: selectedWorkPlan.Department.name.toUpperCase(),
            for: `${selectedWorkPlan.month.toUpperCase()} ${selectedWorkPlan.year}`.trim()
        }

        const data: TableDataFormat[] = workPlanPdfGenFormat(selectedWorkPlan.Tasks)


        if (selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid) {
            const footer: TableFooter = {
                prepared: {
                    name: `${selectedWorkPlan.Employee.fname.toUpperCase()} ${selectedWorkPlan.Employee.mname.Valid ? selectedWorkPlan.Employee.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Employee.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Employee.position.toUpperCase(),
                    signature: selectedWorkPlan.Employee.signature,
                    date: `${new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}`
                },
                reviewed: {
                    name: `${selectedWorkPlan.UnitHead.fname.toUpperCase()} ${selectedWorkPlan.UnitHead.mname.Valid ? selectedWorkPlan.UnitHead.mname.String.toUpperCase() : ""} ${selectedWorkPlan.UnitHead.lname.toUpperCase()}`,
                    position: selectedWorkPlan.UnitHead.position.toUpperCase(),
                    signature: selectedWorkPlan.UnitHead.signature,
                    date: `${new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}`
                },
                approved: {
                    name: `${selectedWorkPlan.Manager.fname.toUpperCase()} ${selectedWorkPlan.Manager.mname.Valid ? selectedWorkPlan.Manager.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Manager.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Manager.position.toUpperCase(),
                    signature: selectedWorkPlan.Manager.signature,
                    date: `${new Date(selectedWorkPlan.manager_approval_at.Time).toLocaleDateString("en-GB")}`
                }
            }

            const res = await generateWorkPlanPdf(selectedWorkPlan.id, {
                header: header,
                data: data,
                footer: footer,
            });
            // TODO: handle faults
            if (!res) return;
            const re = res.data
            console.log(re);
            setRefresh(!refresh);

        } else {
            const footer: TableFooter = {
                prepared: {
                    name: `${selectedWorkPlan.Employee.fname.toUpperCase()} ${selectedWorkPlan.Employee.mname.Valid ? selectedWorkPlan.Employee.mname.String.toUpperCase() : ""} ${selectedWorkPlan.Employee.lname.toUpperCase()}`,
                    position: selectedWorkPlan.Employee.position.toUpperCase(),
                    signature: selectedWorkPlan.Employee.signature,
                    date: `${new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}`
                },
                approved: {
                    name: `${selectedWorkPlan.UnitHead.fname.toUpperCase()} ${selectedWorkPlan.UnitHead.mname.Valid ? selectedWorkPlan.UnitHead.mname.String.toUpperCase() : ""} ${selectedWorkPlan.UnitHead.lname.toUpperCase()}`,
                    position: selectedWorkPlan.UnitHead.position.toUpperCase(),
                    signature: selectedWorkPlan.UnitHead.signature,
                    date: `${new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}`
                },
            }

            const res = await generateWorkPlan2Pdf(selectedWorkPlan.id, {
                header: header,
                data: data,
                footer: footer,
            });
            // TODO: handle faults
            if (!res) return;
            const re = res.data
            console.log(re);
            setRefresh(!refresh);
        }


        setRefresh(!refresh);
    }

    // console.log(selectedWorkPlan)
    const {setButtonType} = useButtonTypeContext()
    return (
        <div className={`w-full h-full flex flex-col gap-4 px-4 pt-20 pb-32 relative`}>
            {
                displayPdf && (
                    <div onClick={() => setDisplayPdf(false)} className={`absolute w-full h-full bg-black/40 z-20`}></div>
                )
            }
            {
                displayPdf && (
                    <div className={`absolute w-full h-full z-20`}>
                        <div className={`w-full h-full relative`}>
                            <div
                                onClick={() => setDisplayPdf(false)}
                                className={`absolute w-fit h-fit p-2 bg-black/40 left-5 top-16 z-20 rounded-full hover:-translate-x-0.5 cursor-pointer`}>
                                <RiArrowGoBackLine className="w-5 h-5  text-white" />
                            </div>
                            {selectedWorkPlan && <PdfRender pdf={PDF_HOST + selectedWorkPlan.file} />}

                        </div>
                        {/*Hello*/}
                        {/*{PDF_HOST + selectedWorkPlan?.file}*/}
                    </div>
                )
            }
            <div className={`w-full flex flex-row gap-4 justify-between items-center`}>
                <div className={`w-fit h-fit flex flex-row gap-4 items-center`}>
                    {
                        !employeeI?.profile_photo || employeeI?.profile_photo?.length <= 0 ? (
                            <>
                                <IoPersonCircle className={`text-stone-400 w-32 h-32`}/>
                            </>
                        ) : (
                            <img className={`w-32 h-32 rounded-full`} src={employeeI?.profile_photo} alt={`${employeeI.fname[0]}${employeeI.mname && employeeI.mname?.length >0 ? `${employeeI.mname[0]}${employeeI.lname[0]}`: employeeI.lname[0] }}`}/>
                        )
                    }
                    <div className={`w-fit h-fit flex flex-col gap-2 text-lg text-[#1c1c1c] font-medium`}>
                        <p>{`${employeeI?.fname} ${employeeI?.mname} ${employeeI?.lname}`}</p>
                        <p>{`${employeeI?.position}`}</p>
                        {/*<p>Performance Avg: 4.5</p>*/}
                    </div>
                </div>
                <div className={`w-fit h-fit flex flex-col gap-4 items-center justify-center`}>
                    {/*{*/}
                    {/*    selectedWorkPlan !== undefined && selectedWorkPlan.Employee.id === employeeI?.user_id ?*/}
                    {/*        (*/}
                    {/*            <div onClick={() => setButtonType(ButtonType.CreateWorkPlan)} className={`w-fit h-fit py-1.5 px-3 bg-white/90 rounded-md cursor-pointer text-lg font-semibold text-[#1c1c1c] shadow-sm hover:shadow hover:bg-white/50 shadow-stone-500/60`}>*/}
                    {/*                <p>Create WorkPlan</p>*/}
                    {/*            </div>*/}
                    {/*        ) :*/}
                    {/*        (*/}
                    {/*            <div></div>*/}
                    {/*        )*/}
                    {/*}*/}

                    <div onClick={() => setButtonType(ButtonType.CreateWorkPlan)}
                         className={`w-fit h-fit py-1.5 px-3 bg-white/90 rounded-md cursor-pointer text-lg font-semibold text-[#1c1c1c] shadow-sm hover:shadow hover:bg-white/50 shadow-stone-500/60`}>
                        <p>Create WorkPlan</p>
                    </div>

                    <select
                        onChange={handleChangeWorkPlan}
                        className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 bg-transparent border-0 text-sm xl:text-lg font-semibold text-[#1c1c1c]`}>
                        {
                            workPlans.map((workPlan: WorkPlanData, i) => (
                                <option key={i}
                                        value={workPlan.id}>{`${workPlan.month.slice(0, 3)} - ${workPlan.year} Work Plan`}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            {/*Work Plan*/}
            {
                selectedWorkPlan ? (
                    <>
                        <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                            <div
                                className={`w-fit text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl text-gray-500 font-semibold relative`}>
                                {`WorkPlan: ${selectedWorkPlan.work_plan_number}`}

                                {
                                    (selectedWorkPlan.Employee.id === employeeI?.user_id && !selectedWorkPlan.is_report_generated) && (

                                        <div
                                            onClick={() => {
                                                setButtonType(ButtonType.EditWorkPlan);
                                                setWorkPlanI(selectedWorkPlan);
                                            }}
                                            className={`absolute z-10 w-fit h-fit right-0 top-6 cursor-pointer`}>
                                            <p className={`text-xs text-orange-400 font-semibold`}>{selectedWorkPlan.is_draft ? "Edit Draft" : "Edit"}</p>
                                        </div>
                                    )
                                }


                                {
                                    (
                                        (((selectedWorkPlan.Employee.s_supervisor_id.Valid && selectedWorkPlan.manager_approval === "approved") && (selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.unit_head_approval === "approved")) || ((selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.unit_head_approval === "approved") && !selectedWorkPlan.Employee.s_supervisor_id.Valid)) && (selectedWorkPlan.Employee.id === employeeI?.user_id) && (selectedWorkPlan.file.length > 0)
                                    )
                                        ?
                                        (
                                            <div
                                                onClick={() => setDisplayPdf(true)}
                                                className={`absolute z-10 w-fit h-fit right-0 bottom-6 cursor-pointer`}>
                                                <BsFileEarmarkPdf
                                                    className={`hover:text-orange-400 text-emerald-400 w-5`}/>
                                            </div>

                                        )
                                        : (
                                            <div></div>
                                        )
                                }
                                {
                                    (((selectedWorkPlan.Employee.s_supervisor_id.Valid && selectedWorkPlan.manager_approval === "approved") && (selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.unit_head_approval === "approved")) || ((selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.unit_head_approval === "approved") && !selectedWorkPlan.Employee.s_supervisor_id.Valid)) && (selectedWorkPlan.Employee.id === employeeI?.user_id) && (selectedWorkPlan.file.length <= 0)

                                        ?
                                        (
                                            <div
                                                onClick={(e) => handleGenerateWorkPlan(e)}
                                                className={`absolute z-10 w-fit h-fit right-0 bottom-8 cursor-pointer`}>
                                                {/*<p className={`text-xs text-orange-400 font-semibold`}>Edit</p>*/}
                                                {/*<TbFileIsr className={` hover:text-emerald-400 text-gray-400 w-5`}/>*/}
                                                <p className={`text-xs hover:text-emerald-400 text-gray-400 font-semibold`}>Generate
                                                    Approved WorkPlan</p>
                                            </div>

                                        )
                                        : (
                                            <div></div>
                                        )
                                }
                            </div>

                            <div className={`w-fit h-fit flex flex-row gap-4 flex-wrap-reverse`}>
                                {
                                    ((selectedWorkPlan.Employee.id === employeeI?.user_id)) ?
                                        (
                                            <div
                                                onClick={() => {
                                                    setButtonType(ButtonType.CreateWorkPlan);
                                                    setWorkPlanI(selectedWorkPlan);
                                                }}
                                                className={`w-fit h-fit py-1.5 px-3 bg-transparent rounded-md cursor-pointer ring-2 ring-orange-500 text-lg font-semibold text-orange-500 hover:text-white shadow-sm hover:shadow hover:bg-orange-500 shadow-stone-500/60`}>
                                                <p>Copy</p>
                                            </div>
                                        ) :
                                        (
                                            <div></div>
                                        )
                                }
                                {
                                    ((((selectedWorkPlan.Employee.s_supervisor_id.Valid && selectedWorkPlan.manager_approval === "approved") && (selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.unit_head_approval === "approved")) || ((selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.unit_head_approval === "approved") && !selectedWorkPlan.Employee.s_supervisor_id.Valid)) && !selectedWorkPlan.is_report_generated) && (selectedWorkPlan.Employee.id === employeeI?.user_id) ?
                                        (
                                            <div
                                                onClick={() => {
                                                    setButtonType(ButtonType.GenerateReport);
                                                    setWorkPlanI(selectedWorkPlan);
                                                }}
                                                className={`w-fit h-fit py-1.5 px-3 bg-orange-500 rounded-md cursor-pointer text-lg font-semibold text-white shadow-sm hover:shadow hover:bg-orange-600 shadow-stone-500/60`}>
                                                <p>Generate Report</p>
                                            </div>
                                        ) :
                                        (
                                            <div></div>
                                        )
                                }
                            </div>
                        </div>
                        {
                            workPlanReStruct(selectedWorkPlan.Tasks).map((obj, index) => (

                                <div key={index} className={`w-full h-fit flex flex-col gap-4  mt-4`}>
                                    <div className={`w-full h-fit flex flex-row justify-between items-center`}>
                                        <p className={` text-2xl font-semibold text-[#1c1c1c]`}>{obj.objectiveName}</p>
                                    </div>
                                    <div className={`w-full h-fit flex flex-col justify-between gap-4`}>
                                        <div
                                            className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                            <div
                                                className={`text-lg xl:text-xl 2xl:text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle flex flex-row gap-x-4`}>
                                                <div className={`w-1/4 h-fit`}>Activity</div>
                                                <div
                                                    className={`w-3/4 h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                    <div className={`w-full h-fit col-span-3`}>Target</div>
                                                    <div className={`w-full h-fit col-span-2`}>Unit of Measure</div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Resource
                                                    </div>
                                                    <div className={`w-full h-fit col-span-2 text-center`}>Timeline
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                            {
                                                obj.activities.length >= 0 && (
                                                    obj.activities.map((activity, i) => (
                                                        <div key={i}
                                                             className={`w-full h-fit flex flex-col gap-4 justify-center relative`}>
                                                            <div
                                                                className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle flex flex-row gap-x-6`}>
                                                                <div
                                                                    className={`w-1/4 h-fit flex flex-row gap-1 items-center`}>
                                                                    <p>{activity.activityName}</p>
                                                                </div>
                                                                {
                                                                    activity.tasks.length <= 0 ?
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-row items-center justify-center`}>
                                                                                <div className={`w-fit h-fit`}>
                                                                                    <p className={`w-fit h-fit rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-gray-500`}>No
                                                                                        targets a located for this
                                                                                        Activity.</p>
                                                                                </div>
                                                                            </div>
                                                                        ) :
                                                                        (
                                                                            <div
                                                                                className={`w-3/4 h-fit flex flex-col gap-4`}>
                                                                                {
                                                                                    activity.tasks.map((task, i) => (

                                                                                        <div key={i}
                                                                                             className={`w-full h-fit justify-items-start text-wrap align-middle grid grid-cols-9 gap-x-4`}>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-3 flex flex-row gap-1 items-center`}>
                                                                                                {task.name}
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.unit_of_measure}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2`}>
                                                                                                <p className={`w-full text-center`}>{task.resource}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className={`w-full h-fit col-span-2 flex flex-col gap-2 relative`}>
                                                                                                <p className={`w-full text-center`}>{new Date(task.dead_line).toLocaleDateString("en-GB")}</p>
                                                                                                <div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        )
                                                                }
                                                            </div>

                                                            <div
                                                                className={`w-full flex flex-row items-center`}>
                                                                <div
                                                                    className={`w-full h-0.5 bg-[#999999] py-[1px]`}></div>
                                                            </div>


                                                        </div>
                                                    ))
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className={`w-full h-fit flex flex-col justify-between gap-4 mt-32`}>
                            <div
                                className={`w-full h-fit bg-white shadow-sm shadow-500/40 rounded-md p-4 flex flex-col gap-2`}>
                                <div
                                    className={`text-2xl font-semibold text-[#1c1c1c] justify-items-start text-wrap align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                    <div className={`w-full h-fit col-span-1 text-center`}>Prepared By</div>


                                    {
                                        selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                            <>
                                                <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                    {
                                                        selectedWorkPlan.unit_head_approval === "rejected" ? (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <FcCancel className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : selectedWorkPlan.unit_head_approval === "approved" ? (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <FcApproval className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <PiDotsThree className={`w-5 h-5 text-gray-500`}/>
                                                            </div>
                                                        )
                                                    }

                                                    Checked By
                                                </div>
                                                <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                    {
                                                        selectedWorkPlan.manager_approval === "rejected" ? (
                                                            <div className={`absolute bottom-3 right-16`}>
                                                                <FcCancel className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : selectedWorkPlan.manager_approval === "approved" ? (
                                                            <div className={`absolute bottom-3 right-16`}>
                                                                <FcApproval className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : (
                                                            <div className={`absolute bottom-3 right-16`}>
                                                                <PiDotsThree className={`w-5 h-5 text-gray-500`}/>
                                                            </div>
                                                        )
                                                    }Approved By
                                                </div>
                                            </>
                                        ) : (

                                            <>
                                                <div className={`w-full h-fit col-span-1 text-center relative`}>
                                                    {
                                                        selectedWorkPlan.unit_head_approval === "rejected" ? (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <FcCancel className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : selectedWorkPlan.unit_head_approval === "approved" ? (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <FcApproval className={`w-5 h-5`}/>
                                                            </div>
                                                        ) : (
                                                            <div className={`absolute bottom-3 right-20`}>
                                                                <PiDotsThree className={`w-5 h-5 text-gray-500`}/>
                                                            </div>
                                                        )
                                                    }

                                                    Checked & Approved By
                                                </div>
                                            </>
                                        )
                                    }


                                </div>
                                <div className={`w-full bg-[#999999] py-[1px]`}></div>
                                <div
                                    className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>

                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.fname} ${selectedWorkPlan.Employee.mname.String} ${selectedWorkPlan.Employee.lname}`}</p>
                                        <p className={`w-full text-center`}>{`${selectedWorkPlan.Employee.position}`}</p>
                                    </div>

                                    {
                                        selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                            <>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.fname} ${selectedWorkPlan.UnitHead.mname.String} ${selectedWorkPlan.UnitHead.lname}`}</p>
                                                    <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.position}`}</p>
                                                </div>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    <p className={`w-full text-center`}>{`${selectedWorkPlan.Manager.fname} ${selectedWorkPlan.Manager.mname.String} ${selectedWorkPlan.Manager.lname}`}</p>
                                                    <p className={`w-full text-center`}>{`${selectedWorkPlan.Manager.position}`}</p>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.fname} ${selectedWorkPlan.UnitHead.mname.String} ${selectedWorkPlan.UnitHead.lname}`}</p>
                                                    <p className={`w-full text-center`}>{`${selectedWorkPlan.UnitHead.position}`}</p>
                                                </div>
                                            </>
                                        )
                                    }

                                </div>

                                <div
                                    className={`text-base text-wrap font-normal text-[#1c1c1c] justify-items-start align-middle grid ${selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? "grid-cols-3" : "grid-cols-2"} gap-x-6`}>
                                    <div
                                        className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                        <p className={`w-full text-center`}>{new Date(selectedWorkPlan.created_at).toLocaleDateString("en-GB")}</p>
                                    </div>

                                    {
                                        selectedWorkPlan.Employee.supervisor_l_id.Valid && selectedWorkPlan.Employee.s_supervisor_id.Valid ? (
                                            <>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    {
                                                        selectedWorkPlan.unit_head_approval_at.Valid && (

                                                            <p className={`w-full text-center`}>{new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                        )
                                                    }
                                                </div>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    {
                                                        selectedWorkPlan.manager_approval_at.Valid && (
                                                            <p className={`w-full text-center`}>{new Date(selectedWorkPlan.manager_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                        )}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`w-full h-fit col-span-1 flex flex-col gap-2 items-center justify-center`}>
                                                    {
                                                        selectedWorkPlan.unit_head_approval_at.Valid && (

                                                            <p className={`w-full text-center`}>{new Date(selectedWorkPlan.unit_head_approval_at.Time).toLocaleDateString("en-GB")}</p>
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </>

                ) : (
                    <div className={`w-full h-full flex flex-row items-center justify-center mb-8`}>
                        <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No workplans made on
                            yet! Or still loading...</p>
                    </div>
                )
            }
        </div>
    );
};

export default EmployeeWorkPlan;