import {client} from "../utils/axios";

export type Division = {
    id: number;
    name: string;
    manager_id?: number;
    department_id: number;
    sub_department_id?: number;
};

export const getDivisions = async () => {
    try {
        const data = await client
            .get<Division[]>("divisions")
            .then(res => res.data);
        return data;
    } catch (err) {}
};

export const getAllDivisionsByDepartmentID = async (param: number) => {
    try {
        return await client
            .get<Division[]>(`department/${param.toString().trim()}/divisions`)
            .then(res => res.data);
    } catch (err) {}
};

export const getAllDivisionsBySubDepartmentID = async (param: number) => {
    try {
        return await client
            .get<Division[]>(`sub_department/${param.toString().trim()}/divisions`)
            .then(res => res.data);
    } catch (err) {}
};

export const getDivison = async (param: number) => {
    try {
        const data = await client
            .get<Division>("divisions/" + param.toString().trim())
            .then(res => res.data);
        return data;
    } catch (err) {}
};
