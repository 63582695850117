export const scoreFilter = (score: string): string => {
    switch (score) {
        case 'OUTSTANDING':
            return `bg-[#10b981] text-[#1C1C1C]`;

        case 'EXCELLENT':
            return `bg-[#8DEB8D] text-[#1C1C1C]`;

        case 'GOOD':
            return `bg-[#F8E7A5] text-[#1C1C1C]`;

        case 'ACCEPTABLE':
            return `bg-[#FAE049] text-[#1C1C1C]`;

        case 'UNSATISFACTORY':
            return `bg-[#F03333] text-[#1C1C1C]`;
        case 'UNACCEPTABLE':
            return `bg-[#F03333] text-[#1C1C1C]`;

        default:
            return `bg-[#E6E6E6] text-[#1C1C1C]`;
    }
}


export const scoreColour = (score: string): string => {
    switch (score) {
        case 'OUTSTANDING':
            return `#10b981`;

        case 'EXCELLENT':
            return `#8DEB8D`;

        case 'GOOD':
            return `#F8E7A5`;

        case 'ACCEPTABLE':
            return `#FAE049`;

        case 'UNSATISFACTORY':
            return `#F03333`;
        case 'UNACCEPTABLE':
            return `#F03333`;

        default:
            return `#E6E6E6`;
    }
}

export const statusScoreMatch = (status: string): string => {
    switch (status) {
        case 'committee':
            return `OUTSTANDING`;
        case 'head':
            return `EXCELLENT`;
        case 'draft':
            return `GOOD`;
        case 'infancy':
            return `ACCEPTABLE`;
        case 'no_evidence':
            return `UNSATISFACTORY`;
        case 'delayed':
            return `ACTIVITY DELAYED`;
        default:
            return `NOT APPLICABLE`;
    }
}

export const ScoreValueMatcher = (score: string): number|null => {
    switch (score) {
        case 'OUTSTANDING':
            return 4;

        case 'EXCELLENT':
            return 3;

        case 'GOOD':
            return 2;

        case 'ACCEPTABLE':
            return 1;

        case 'UNSATISFACTORY':
            return 0;
        case 'UNACCEPTABLE':
            return 0;

        default:
            return null;
    }
}


export const ScoreValueAvgMatcher = (score: number): string => {
    if (score < 0.5) return 'UNSATISFACTORY';
    else if (score < 1.5) return 'ACCEPTABLE';
    else if (score < 2.5) return 'GOOD';
    else if (score < 3.5) return 'EXCELLENT';
    else if (score < 3.5) return 'EXCELLENT';
    else return 'OUTSTANDING';
}


export const statusScore = (status: string): string => {
    switch (status) {
        case 'committee':
            return `Presented to and approved by the relevant committees [100% Complete]`;
        case 'head':
            return `Presented to and approved by the Head of Department [75% Complete]`;
        case 'draft':
            return `Presented in draft format, no review has been undertaken [50% Complete]`;
        case 'infancy':
            return `Key deliverables still in infancy stage [Outline Format- 25% Complete]`;
        case 'no_evidence':
            return `No evidence of Work on the Key Deliverables [0%]`;
        case 'delayed':
            return `Activity Delayed`;
        default:
            return `NOT APPLICABLE`;
    }
}