import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {client} from "../utils/axios";

export type Department = {
    id: number;
    name: string;
    director_id?: number;
};

export const getDepartments = async () => {
    try {
        const data = await client
            .get<Department[]>("departments")
            .then(res => res.data);
        // console.log(data)
        return data;
    } catch (err) {}
};


export const getDepartment = async (param: number) => {
    try {
        return await client
            .get<Department>("departments/" + param.toString().trim())
            .then(res => res.data);
    } catch (err) {}
};
