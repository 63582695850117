import {client} from "../utils/axios";

export type DepartmentAnalytics = {
    department_name: string;
    department_id: number;
    total_employees: number;
    average_performance: number;
    total_work_plans: number;
    total_reports: number;
    month: string;
    year: number;
}

type DepartmentAnalyticsReq = {
    month: string;
    year: number;
}

export const getDepartmentAnalytics = async (req: DepartmentAnalyticsReq) => {
    try {
        return await client
            .post<DepartmentAnalytics[]>("analyticsDepartments", req)
            .then(res => res.data);
    } catch (err) {}
}


// DIVISION

export type DivisionAnalytics = {
    division_name: string;
    division_id: number;
    total_employees: number;
    average_performance: number;
    total_work_plans: number;
    total_reports: number;
    month: string;
    year: number;
}

type DivisionAnalyticsReq = {
    division_id: number;
    month: string;
    year: number;
}

export const getDivisionsAnalytics = async (req: DivisionAnalyticsReq) => {
    try {
        return await client
            .post<DivisionAnalytics[]>("analyticsDivisions", req)
            .then(res => res.data);
    } catch (err) {}
}

export const getDivisionAnalytics = async (req: DivisionAnalyticsReq) => {
    try {
        return await client
            .post<DivisionAnalytics>("analyticsDivision", req)
            .then(res => res.data);
    } catch (err) {}
}

export const getDivisionTBAAnalytics = async (req: DepartmentAnalyticsReq) => {
    try {
        return await client
            .post<DivisionAnalytics>("analyticsTBADivision", req)
            .then(res => res.data);
    } catch (err) {}
}



// EMPLOYEE


export type EmployeeAnalytics = {
    fname: string;
    mname?: string;
    lname: string;
    position: string;
    employee_id: number;
    two_approval: boolean;
    report_generated: boolean;
    average_performance: number;
    supervisor_approval_work_plan: string;
    s_supervisor_approval_work_plan: string;
    supervisor_approval_report: string;
    s_supervisor_approval_report: string;
    report_file: string;
    work_plan_file: string;
    month: string;
    year: number;
}

type EmployeeAnalyticsReq = {
    division_id: number;
    month: string;
    year: number;
}


export const getEmployeesAnalytics = async (req: EmployeeAnalyticsReq) => {
    try {
        return await client
            .post<EmployeeAnalytics[]>("analyticsEmployees", req)
            .then(res => {
                if (res.data === null) return [];
                return res.data
            });
    } catch (err) {}
}

export const getTBAEmployeesAnalytics = async (req: DepartmentAnalyticsReq) => {
    try {
        return await client
            .post<EmployeeAnalytics[]>("analyticsTBAEmployees", req)
            .then(res => {
                if (res.data === null) return [];
                return res.data
            });
    } catch (err) {}
}