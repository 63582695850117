export const financialYear = (): string => {
    let current_date = new Date();

    let current_year = current_date.getFullYear();

    let fy_end = new Date(current_year, 6, 1, 0,0,0);

    let fy = "";

    if (fy_end > current_date) {
        let kk = current_year-1;
        let k = parseInt(String(kk).split("").slice(2).join(""));
        let k2 = parseInt(String(current_year).split("").slice(2).join(""));
        fy = `FY-${k}/${k2}`;
    } else {
        let kk = current_year+1;
        let k = parseInt(String(kk).split("").slice(2).join(""));
        let k2 = parseInt(String(current_year).split("").slice(2).join(""));
        fy = `FY-${k2}/${k}`;
    }
    return fy;
}

export const is = () => {
  
}