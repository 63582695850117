import React, {useEffect, useState} from 'react';
import {useAuth} from "../../context/authContext";
import {Link, useNavigate} from "react-router-dom";
import {IoAnalyticsOutline, IoPerson, IoPersonCircleOutline} from "react-icons/io5";
import {LuLogOut} from "react-icons/lu";
import {months, years} from "../../utils/scoresGraphReStructure";
import {
    DepartmentAnalytics,
    DivisionAnalytics, EmployeeAnalytics,
    getDepartmentAnalytics, getDivisionAnalytics,
    getDivisionsAnalytics, getDivisionTBAAnalytics, getEmployeesAnalytics, getTBAEmployeesAnalytics
} from "../../services/analyticsService";
import {DepartmentRanking} from "../../utils/sortHelper";
import {IoIosPeople} from "react-icons/io";
import {GrPlan} from "react-icons/gr";
import { TbReport } from 'react-icons/tb';
import {PiDotsThree} from "react-icons/pi";
import {FcApproval, FcCancel} from "react-icons/fc";
import Spinner from "../comps/spinner";
import {RiArrowGoBackLine} from "react-icons/ri";
import PdfRender from "../comps/pdfRender";
import {PDF_HOST} from "../../utils/api";
import {scoreFilter} from "../../utils/scoreUtil";

const AnalyticPage = () => {
    const [toggle, setToggle] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [fileToggle, setFileToggle] = useState<boolean>(false);
    const [file, setFile] = useState<string>("");

    const [selectedAuditToggle, setSelectedAuditToggle] = useState<boolean>(false);
    const [selectedTBAToggle, setSelectedTBAToggle] = useState<boolean>(false);

    const [selectedYear, setSelectedYear] = useState<number>(2025);
    const [selectedMonth, setSelectedMonth] = useState<string>("January");
    const [selectedDepartment, setSelectedDepartment] = useState<DepartmentAnalytics | undefined>(undefined);
    const [selectedAudit, setSelectedAudit] = useState<DivisionAnalytics | undefined>(undefined);
    const [selectedTBA, setSelectedTBA] = useState<DivisionAnalytics | undefined>(undefined);
    const [selectedDivision, setSelectedDivision] = useState<DivisionAnalytics | undefined>(undefined);
    const [selectedEmployee, setSelectedEmployee] = useState<EmployeeAnalytics | undefined>(undefined);


    const {employeeI} = useAuth();
    const navigate = useNavigate();

    const [departmentsAnalytics, setDepartmentsAnalyticss] = useState<DepartmentAnalytics[]>([]);
    const [divisionsAnalytics, setDivisionsAnalyticss] = useState<DivisionAnalytics[]>([]);
    const [employeesAnalytics, setEmployeesAnalyticss] = useState<EmployeeAnalytics[]>([]);


    useEffect(() => {
        if (!employeeI){
            console.log("Not authenticated");
            navigate("/login", { replace: true });
            return;
        }
        // console.log(employeeI);
        if (employeeI.role !== "ceo" && employeeI.department !== 7 && employeeI.email !== "aziryawulawo@kiiramotors.com" && employeeI?.email !== "mmkibuuka@kiiramotors.com" && employeeI?.email !== "pnnambatya@kiiramotors.com" && employeeI.user_id !== 1) {
            navigate("/dashboard", { replace: true });
            return;
        }
        setLoading(true);
        fetch().then()
        setLoading(false);

    }, []);

    const fetch = async () => {
        // await getDepartments().then(
        //     res => {
        //         if (res) {
        //             setDepartments(res);
        //         }
        //     }
        // )
        await getDepartmentAnalytics({
            year: selectedYear,
            month: selectedMonth,
        }).then(
            res => {
                if (res) {
                    setDepartmentsAnalyticss(res.sort((a, b) => DepartmentRanking(a.department_id) - DepartmentRanking(b.department_id)));
                    // console.log(res)
                }
            }
        )

        await getDivisionAnalytics({
            division_id: 29,
            year: selectedYear,
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setSelectedAudit(res);
                // console.log(res)
            }
        })

        await getDivisionTBAAnalytics({
            year: selectedYear,
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setSelectedTBA(res);
                // console.log(res)
            }
        })
    }

    const handleYearChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (loading) return;
        setLoading(true);
        await getDepartmentAnalytics({
            year: parseInt(e.target.value),
            month: selectedMonth,
        }).then(
            res => {
                if (res) {
                    setDepartmentsAnalyticss(res.sort((a, b) => DepartmentRanking(a.department_id) - DepartmentRanking(b.department_id)));
                    // console.log(res)
                }
            }
        )

        await getDivisionTBAAnalytics({
            year: parseInt(e.target.value),
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setSelectedTBA(res);
                // console.log(res)
            }
        })

        await getTBAEmployeesAnalytics({
            year: parseInt(e.target.value),
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setEmployeesAnalyticss(res);
                // console.log(res)
            }
        })

        await getDivisionAnalytics({
            division_id: 29,
            year: parseInt(e.target.value),
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setSelectedAudit(res);
                // console.log(res)
            }
        })

        if (!selectedDepartment) return;
        await getDivisionsAnalytics({
            division_id: selectedDepartment.department_id,
            year: parseInt(e.target.value),
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setDivisionsAnalyticss(res);
                // console.log(res)
            }
        })

        if (!selectedDivision) return;
        await getEmployeesAnalytics({
            division_id: selectedDivision.division_id,
            year: parseInt(e.target.value),
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setEmployeesAnalyticss(res);
                // console.log(res)
            }
        })

        setLoading(false);

    }

    const handleMonthChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (loading) return;
        setLoading(true);
        await getDepartmentAnalytics({
            year: selectedYear,
            month: e.target.value,
        }).then(
            res => {
                if (res) {
                    setDepartmentsAnalyticss(res.sort((a, b) => DepartmentRanking(a.department_id) - DepartmentRanking(b.department_id)));
                    // console.log(res)
                }
            }
        )
        await getDivisionAnalytics({
            division_id: 29,
            year: selectedYear,
            month: e.target.value,
        }).then(res => {
            if (res) {
                setSelectedAudit(res);
                // console.log(res)
            }
        })

        await getDivisionTBAAnalytics({
            year: selectedYear,
            month: e.target.value,
        }).then(res => {
            if (res) {
                setSelectedTBA(res);
                // console.log(res)
            }
        })

        await getTBAEmployeesAnalytics({
            year: selectedYear,
            month: e.target.value,
        }).then(res => {
            if (res) {
                setEmployeesAnalyticss(res);
                // console.log(res)
            }
        })

        if (!selectedDepartment) return;
        await getDivisionsAnalytics({
            division_id: selectedDepartment.department_id,
            year: selectedYear,
            month: e.target.value,
        }).then(res => {
            if (res) {
                setDivisionsAnalyticss(res);
                // console.log(res)
            }
        })
        if (!selectedDivision) return;
        await getEmployeesAnalytics({
            division_id: selectedDivision.division_id,
            year: selectedYear,
            month: e.target.value,
        }).then(res => {
            if (res) {
                setEmployeesAnalyticss(res);
                // console.log(res)
            }
        })
        setLoading(false);

    }

    const handleDivisionChange = async (e: React.MouseEvent<HTMLDivElement>, id: number) => {
        e.preventDefault()
        if (loading) return;
        setLoading(true);
        await getDivisionsAnalytics({
            division_id: id,
            year: selectedYear,
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setDivisionsAnalyticss(res);
                // console.log(res)
            }
        })
        setLoading(false);
    }


    const handleEmployeeChange = async (e: React.MouseEvent<HTMLDivElement>, id: number) => {
        e.preventDefault()
        // console.log(id)
        if (loading) return;
        setLoading(true);
        await getEmployeesAnalytics({
            division_id: id,
            year: selectedYear,
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setEmployeesAnalyticss(res);
                // console.log(res)
            }
        })
        setLoading(false);
    }

    const handleTBAEmployeeChange = async (e: React.MouseEvent<HTMLDivElement>, ) => {
        e.preventDefault()
        // console.log(id)
        if (loading) return;
        setLoading(true);
        await getTBAEmployeesAnalytics({
            year: selectedYear,
            month: selectedMonth,
        }).then(res => {
            if (res) {
                setEmployeesAnalyticss(res);
                // console.log(res)
            }
        })
        setLoading(false);
    }


    return (
        <div className={`w-full h-full relative`}>
            {
                loading && (
                    <div className={`absolute z-20 w-full h-full bg-black/20`}>
                    </div>
                )
            }

            {
                loading && (

                    <div className={`absolute z-30 w-full h-full transform translate-x-1/2 translate-y-1/3`}>
                        <Spinner />
                    </div>
                )
            }


            {
                fileToggle && (
                    <div onClick={() => {
                        setFile("")
                        setFileToggle(false)
                    }} className={`absolute w-full h-full bg-black/40 z-20`}></div>
                )
            }
            {
                fileToggle && (
                    <div className={`absolute w-full h-full z-20`}>
                        <div className={`w-full h-full relative`}>
                            <div
                                onClick={() => {
                                    setFile("")
                                    setFileToggle(false)
                                }}
                                className={`absolute w-fit h-fit p-2 bg-black/40 left-5 top-16 z-20 rounded-full hover:-translate-x-0.5 cursor-pointer`}>
                                <RiArrowGoBackLine className="w-5 h-5  text-white" />
                            </div>
                            {file.length > 0 && <PdfRender pdf={PDF_HOST + file} />}

                        </div>
                        {/*Hello*/}
                        {/*{PDF_HOST + selectedWorkPlan?.file}*/}
                    </div>
                )
            }


            <div onClick={() => {setToggle(!toggle)}} className={`absolute w-fit h-fit top-4 right-4`}>
                {
                    !employeeI?.profile_photo || employeeI?.profile_photo?.length <= 0 ? (
                        <>
                            <IoPerson className={`text-stone-400 w-16 h-16`} />
                        </>
                    ) : (
                        <img className={`w-16 h-16 rounded-full`} src={employeeI?.profile_photo} alt={`${employeeI.fname[0]}${employeeI.mname && employeeI.mname?.length >0 ? `${employeeI.mname[0]}${employeeI.lname[0]}`: employeeI.lname[0] }}`}/>
                    )
                }
            </div>
            {
                toggle && (
                    <div
                        className={`absolute w-32 rounded-md bg-white h-fit py-2 px-4 top-20 right-2 flex flex-col gap-1.5 shadow-sm shadow-stone-800/60`}>
                        <div onClick={() => {
                            setToggle(false)
                            navigate("/dashboard", { replace: true });
                        }}
                             className={`w-full h-fit flex flex-row items-center justify-between gap-2  cursor-pointer`}>
                            <p className={`text-xs`}>My Summary</p>
                            <IoPersonCircleOutline className={`text-stone-700 w-4 h-4`}/>
                        </div>
                        <Link to={"/logout"} >
                            <div className={`w-full h-fit flex flex-row items-center justify-between gap-2 cursor-pointer`}>
                                <p className={`text-xs`}>Logout</p>
                                <LuLogOut className={`text-stone-700 w-4 h-4`}/>
                            </div>
                        </Link>
                    </div>
                )
            }

            <div className={`px-6 pt-32 w-full h-full`}>
                <div className={`w-full h-fit flex flex-row items-center justify-between`}>
                    <p className={`font-medium text-lg text-[#1c1c1c]`}>Departments</p>
                    <div className={`w-fit h-fit flex flex-row items-center gap-4 justify-between`}>
                        {
                                <select
                                    onChange={(e) => {
                                        setSelectedMonth(e.target.value)
                                        handleMonthChange(e).then()
                                    }}
                                    className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                    {
                                        months.map((month, i) => (
                                            <option value={month} key={i}>{month}</option>
                                        ))
                                    }
                                </select>
                        }
                        {
                                <select
                                    onChange={(e) => {
                                        setSelectedYear(parseInt(e.target.value))
                                        handleYearChange(e).then()
                                    }}
                                    className={`w-fit px-2 py-1.5 outline-0 focus:outline-0 border-0 text-sm font-semibold text-[#1c1c1c]`}>
                                    {
                                        years.map(year => (
                                            <option value={year} key={year}>{year}</option>
                                        ))
                                    }
                                </select>
                        }

                    </div>

                </div>
                {
                    departmentsAnalytics.length > 0 ? (

                        <div

                            className={`w-full min-h-96 h-fit bg-white shadow-sm shadow-500/40 rounded-md py-6 px-5 flex flex-col justify-between gap-6`}>
                            {
                                departmentsAnalytics.map((department, i) => (
                                    <div
                                        onClick={(e) => {
                                            handleDivisionChange(e, department.department_id).then()
                                            setSelectedDepartment(department)
                                            setSelectedAuditToggle(false)
                                            setSelectedTBAToggle(false)
                                            setSelectedDivision(undefined)
                                            setEmployeesAnalyticss([])

                                        }}
                                        key={i} className={`w-full h-fit px-2 py-1.5 grid grid-cols-6 ${selectedDepartment && selectedDepartment.department_id === department.department_id ? "bg-orange-500 text-white" : ""} gap-2 shadow-sm shadow-gray-500/40 justify-between items-center rounded-sm hover:shadow cursor-pointer`}>
                                        <span className={`col-span-2 text-base font-medium flex flex-row justify-start items-center`}>
                                            {department.department_name}
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <IoIosPeople  className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{department.total_employees}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <GrPlan className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{department.total_work_plans}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <TbReport   className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{department.total_reports}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-end gap-1`}>
                                            <span
                                                className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(
                                                    (department.average_performance < 0.5 && department.total_reports > 0) ? "UNACCEPTABLE" : department.average_performance >= 0.5 && department.average_performance < 1.5 ? "ACCEPTABLE" : department.average_performance >= 1.5 && department.average_performance < 2.5 ? "GOOD" : department.average_performance >= 2.5 && department.average_performance < 3.5 ? "EXCELLENT" : department.average_performance >= 3.5 && department.average_performance <= 4 ? "OUTSTANDING" : "--"
                                                )} font-semibold`}>
                                                {(department.average_performance < 0.5 && department.total_reports > 0) ? "UNACCEPTABLE" : department.average_performance >= 0.5 && department.average_performance < 1.5 ? "ACCEPTABLE" : department.average_performance >= 1.5 && department.average_performance < 2.5 ? "GOOD" : department.average_performance >= 2.5 && department.average_performance < 3.5 ? "EXCELLENT" : department.average_performance >= 3.5 && department.average_performance <= 4 ? "OUTSTANDING" : "--"}
                                            </span>
                                            {/*<IoAnalyticsOutline className={`w-5 h-5`}/>*/}
                                            {/*<p className={`text-sm font-bold`}>Avg: {department.average_performance.toFixed(1)}</p>*/}
                                        </span>
                                    </div>
                                ))
                            }
                            {

                                selectedAudit && (
                                    <div
                                        onClick={(e) => {
                                            handleEmployeeChange(e, selectedAudit?.division_id).then()
                                            setSelectedDepartment(undefined)
                                            setSelectedAuditToggle(true)
                                            setSelectedTBAToggle(false)
                                            setSelectedDivision(selectedAudit)
                                        }}
                                        className={`w-full h-fit px-2 py-1.5 grid grid-cols-6 ${selectedAuditToggle && selectedDivision?.division_id === selectedAudit.division_id ? "bg-orange-500 text-white" : ""} gap-2 shadow-sm shadow-gray-500/40 justify-between items-center rounded-sm hover:shadow cursor-pointer`}>
                                        <span className={`col-span-2 text-base font-medium flex flex-row justify-start items-center`}>
                                            {selectedAudit.division_name}
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <IoIosPeople  className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{selectedAudit.total_employees}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <GrPlan className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{selectedAudit.total_work_plans}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <TbReport   className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{selectedAudit.total_reports}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-end gap-1`}>
                                            <span
                                                className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(
                                                    (selectedAudit.average_performance < 0.5 && selectedAudit.total_reports > 0) ? "UNACCEPTABLE" : selectedAudit.average_performance >= 0.5 && selectedAudit.average_performance < 1.5 ? "ACCEPTABLE" : selectedAudit.average_performance >= 1.5 && selectedAudit.average_performance < 2.5 ? "GOOD" : selectedAudit.average_performance >= 2.5 && selectedAudit.average_performance < 3.5 ? "EXCELLENT" : selectedAudit.average_performance >= 3.5 && selectedAudit.average_performance <= 4 ? "OUTSTANDING" : "--"
                                                )} font-semibold`}>
                                                {(selectedAudit.average_performance < 0.5 && selectedAudit.total_reports > 0) ? "UNACCEPTABLE" : selectedAudit.average_performance >= 0.5 && selectedAudit.average_performance < 1.5 ? "ACCEPTABLE" : selectedAudit.average_performance >= 1.5 && selectedAudit.average_performance < 2.5 ? "GOOD" : selectedAudit.average_performance >= 2.5 && selectedAudit.average_performance < 3.5 ? "EXCELLENT" : selectedAudit.average_performance >= 3.5 && selectedAudit.average_performance <= 4 ? "OUTSTANDING" : "--"}
                                            </span>
                                            {/*<IoAnalyticsOutline className={`w-5 h-5`}/>*/}
                                            {/*<p className={`text-sm font-bold`}>Avg: {selectedAudit.average_performance.toFixed(1)}</p>*/}
                                        </span>
                                    </div>

                                )
                            }
                            {

                                selectedTBA && (
                                    <div
                                        onClick={(e) => {
                                            handleTBAEmployeeChange(e).then()
                                            setSelectedDepartment(undefined)
                                            setSelectedAuditToggle(false)
                                            setSelectedTBAToggle(true)
                                            setSelectedDivision(selectedTBA)
                                        }}
                                        className={`w-full h-fit px-2 py-1.5 grid grid-cols-6 ${selectedTBAToggle && selectedDivision?.division_id === selectedTBA.division_id ? "bg-orange-500 text-white" : ""} gap-2 shadow-sm shadow-gray-500/40 justify-between items-center rounded-sm hover:shadow cursor-pointer`}>
                                        <span className={`col-span-2 text-base font-medium flex flex-row justify-start items-center`}>
                                            {selectedTBA.division_name}
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <IoIosPeople  className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{selectedTBA.total_employees}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <GrPlan className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{selectedTBA.total_work_plans}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            <TbReport   className={`w-5 h-5`}/>
                                            <p className={`text-sm font-bold`}>{selectedTBA.total_reports}</p>
                                        </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-end gap-1`}>

                                            <span
                                                className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(
                                                    (selectedTBA.average_performance < 0.5 && selectedTBA.total_reports > 0) ? "UNACCEPTABLE" : selectedTBA.average_performance >= 0.5 && selectedTBA.average_performance < 1.5 ? "ACCEPTABLE" : selectedTBA.average_performance >= 1.5 && selectedTBA.average_performance < 2.5 ? "GOOD" : selectedTBA.average_performance >= 2.5 && selectedTBA.average_performance < 3.5 ? "EXCELLENT" : selectedTBA.average_performance >= 3.5 && selectedTBA.average_performance <= 4 ? "OUTSTANDING" : "--"
                                                )} font-semibold`}>
                                                {(selectedTBA.average_performance < 0.5 && selectedTBA.total_reports > 0) ? "UNACCEPTABLE" : selectedTBA.average_performance >= 0.5 && selectedTBA.average_performance < 1.5 ? "ACCEPTABLE" : selectedTBA.average_performance >= 1.5 && selectedTBA.average_performance < 2.5 ? "GOOD" : selectedTBA.average_performance >= 2.5 && selectedTBA.average_performance < 3.5 ? "EXCELLENT" : selectedTBA.average_performance >= 3.5 && selectedTBA.average_performance <= 4 ? "OUTSTANDING" : "--"}
                                            </span>

                                            {/*<IoAnalyticsOutline className={`w-5 h-5`}/>*/}
                                            {/*<p className={`text-sm font-bold`}>Avg: {selectedTBA.average_performance.toFixed(1)}</p>*/}
                                        </span>
                                    </div>

                                )
                            }
                        </div>
                    ):(
                        <div className={`w-full h-64 flex flex-row justify-start items-end`}>
                            <div className={`w-full h-full flex flex-row items-center justify-center mb-8`}>
                                <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No scores to report on yet!</p>
                            </div>
                        </div>
                    )
                }

                {
                    selectedTBAToggle || selectedAuditToggle ? "" : (

                        (divisionsAnalytics.length > 0) ? (

                            <div

                                className={`w-full min-h-56 h-fit bg-white shadow-sm shadow-500/40 rounded-md py-6 px-5 flex flex-col justify-between gap-6 my-28`}>
                                {
                                    divisionsAnalytics.map((division, i) => (
                                        <div
                                            onClick={(e) => {
                                                handleEmployeeChange(e, division.division_id).then()
                                                setSelectedAuditToggle(false)
                                                setSelectedTBAToggle(false)
                                                setSelectedDivision(division)
                                            }}
                                            key={i} className={`w-full h-fit px-2 py-1.5 grid grid-cols-6 ${selectedDivision && selectedDivision.division_id === division.division_id ? "bg-emerald-500 text-white" : ""} gap-2 shadow-sm shadow-gray-500/40 justify-between items-center rounded-sm hover:shadow cursor-pointer`}>
                                            <span className={`col-span-2 text-base font-medium flex flex-row justify-start items-center`}>
                                                {division.division_name}
                                            </span>
                                            <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                                <IoIosPeople  className={`w-5 h-5`}/>
                                                <p className={`text-sm font-bold`}>{division.total_employees}</p>
                                            </span>
                                            <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                                <GrPlan className={`w-5 h-5`}/>
                                                <p className={`text-sm font-bold`}>{division.total_work_plans}</p>
                                            </span>
                                            <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                                <TbReport   className={`w-5 h-5`}/>
                                                <p className={`text-sm font-bold`}>{division.total_reports}</p>
                                            </span>
                                            <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-end gap-1`}>
                                                <span
                                                    className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(
                                                        (division.average_performance < 0.5 && division.total_reports > 0) ? "UNACCEPTABLE" : division.average_performance >= 0.5 && division.average_performance < 1.5 ? "ACCEPTABLE" : division.average_performance >= 1.5 && division.average_performance < 2.5 ? "GOOD" : division.average_performance >= 2.5 && division.average_performance < 3.5 ? "EXCELLENT" : division.average_performance >= 3.5 && division.average_performance <= 4 ? "OUTSTANDING" : "--"
                                                        )} font-semibold`}>
                                                    {(division.average_performance < 0.5 && division.total_reports > 0) ? "UNACCEPTABLE" : division.average_performance >= 0.5 && division.average_performance < 1.5 ? "ACCEPTABLE" : division.average_performance >= 1.5 && division.average_performance < 2.5 ? "GOOD" : division.average_performance >= 2.5 && division.average_performance < 3.5 ? "EXCELLENT" : division.average_performance >= 3.5 && division.average_performance <= 4 ? "OUTSTANDING" : "--"}
                                                </span>
                                                {/*<IoAnalyticsOutline className={`w-5 h-5`}/>*/}
                                                {/*<p className={`text-sm font-bold`}>Avg: {division.average_performance.toFixed(1)}</p>*/}
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                        ):(
                            <div className={`w-full h-64 flex flex-row justify-start bg-white shadow-sm shadow-500/40 rounded-md py-6 px-5 items-end my-28`}>
                                <div className={`w-full h-full flex flex-row items-center justify-center`}>
                                    <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No Depatment Selected!</p>
                                </div>
                            </div>
                        )
                    )

                }

                {
                    (employeesAnalytics.length > 0) ? (

                        <div

                            className={`w-full min-h-56 h-fit bg-white shadow-sm shadow-500/40 rounded-md py-6 px-5 flex flex-col justify-between gap-6 my-28`}>
                            {
                                employeesAnalytics.map((employee, i) => (
                                    <div
                                        // onClick={(e) => {
                                        //     setSelectedAuditToggle(false)
                                        //     setSelectedTBAToggle(false)
                                        //     setSelectedDivision(division)
                                        // }}
                                        key={i} className={`w-full h-fit px-2 py-1.5 grid grid-cols-7 ${selectedEmployee && selectedEmployee.employee_id === employee.employee_id ? "bg-emerald-500 text-white" : ""} gap-2 shadow-sm shadow-gray-500/40 justify-between items-center rounded-sm hover:shadow cursor-pointer`}>
                                            <span className={`col-span-2 text-base font-medium flex flex-row justify-start items-center`}>
                                                {`${employee.fname} ${employee.mname} ${employee.lname}`}
                                            </span>
                                        <span className={`col-span-2 w-full h-fit text-sm font-medium flex flex-row items-center justify-start gap-1`}>
                                                {employee.position}
                                            </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            {
                                                employee.work_plan_file.length > 0 ? (
                                                    <GrPlan onClick={ () => {
                                                        setFile(employee.work_plan_file)
                                                        setFileToggle(true)}}   className={`w-5 h-5`}/>
                                                ) : (
                                                    <GrPlan className={`w-5 h-5`}/>
                                                )
                                            }

                                            <div className={`w-full h-fit flex flex-row`}>
                                                    {
                                                        employee.two_approval ? (
                                                            <>
                                                                {
                                                                    employee.supervisor_approval_work_plan === "approved" ? (
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    ) : employee.supervisor_approval_work_plan === "rejected" ? (
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    ) : employee.supervisor_approval_work_plan === "pending" ? (

                                                                            <PiDotsThree
                                                                                className={`w-5 h-5 text-gray-500`}/>

                                                                    ) : (
                                                                        <p className={`text-xs font-medium text-gray-600`}>-</p>
                                                                    )
                                                                }
                                                                {
                                                                    employee.s_supervisor_approval_work_plan === "approved" ? (
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    ) : employee.s_supervisor_approval_work_plan === "rejected" ? (
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    ) : employee.s_supervisor_approval_work_plan === "pending" ? (

                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>

                                                                    ) : (
                                                                        <p className={`text-xs font-medium text-gray-600`}>-</p>
                                                                    )
                                                                }
                                                            </>

                                                        ) : (
                                                            <>
                                                                {
                                                                    employee.supervisor_approval_work_plan === "approved" ? (
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    ) : employee.supervisor_approval_work_plan === "rejected" ? (
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    ) : employee.supervisor_approval_work_plan === "pending" ? (

                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>

                                                                    ) : (
                                                                        <p className={`text-xs font-medium text-gray-600`}>-</p>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>



                                            </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-center gap-1`}>
                                            {
                                                employee.report_file.length > 0 ? (
                                                    <TbReport onClick={ () => {
                                                        setFile(employee.report_file)
                                                        setFileToggle(true)}} className={`w-5 h-5`}/>
                                                    ) : (
                                                    <TbReport   className={`w-5 h-5`}/>
                                                )
                                            }

                                            {
                                                employee.report_generated ? (

                                                <div className={`w-full h-fit flex flex-row`}>
                                                    {
                                                        employee.two_approval ? (
                                                            <>
                                                                {
                                                                    employee.supervisor_approval_report === "approved" ? (
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    ) : employee.supervisor_approval_report === "rejected" ? (
                                                                         <FcCancel className={`w-5 h-5`}/>
                                                                    ) : employee.supervisor_approval_report === "pending" ? (

                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>

                                                                    ) : (
                                                                        <p className={`text-xs font-medium text-gray-600`}>-</p>
                                                                    )
                                                                }
                                                                {
                                                                    employee.s_supervisor_approval_report === "approved" ? (
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    ) : employee.s_supervisor_approval_report === "rejected" ? (
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    ) : employee.s_supervisor_approval_report === "pending" ? (

                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>

                                                                    ) : (
                                                                        <p className={`text-xs font-medium text-gray-600`}>-</p>
                                                                    )
                                                                }
                                                            </>

                                                        ) : (
                                                            <>
                                                                {
                                                                    employee.supervisor_approval_report === "approved" ? (
                                                                        <FcApproval className={`w-5 h-5`}/>
                                                                    ) : employee.supervisor_approval_report === "rejected" ? (
                                                                        <FcCancel className={`w-5 h-5`}/>
                                                                    ) : employee.supervisor_approval_report === "pending" ? (

                                                                        <PiDotsThree
                                                                            className={`w-5 h-5 text-gray-500`}/>

                                                                    ) : (
                                                                        <p className={`text-xs font-medium text-gray-600`}>-</p>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                ): <p className={`text-xs font-medium text-gray-600`}>No report generated</p>
                                            }
                                            </span>
                                        <span className={`col-span-1 w-full h-fit flex flex-row items-center justify-end gap-1`}>
                                            <span
                                                className={`min-w-32 w-fit text-center rounded-full min-h-8 h-fit py-1.5 px-4 ${scoreFilter(
                                                    (employee.average_performance < 0.5 && employee.supervisor_approval_report === "approved") ? "UNACCEPTABLE" : employee.average_performance >= 0.5 && employee.average_performance < 1.5 ? "ACCEPTABLE" : employee.average_performance >= 1.5 && employee.average_performance < 2.5 ? "GOOD" : employee.average_performance >= 2.5 && employee.average_performance < 3.5 ? "EXCELLENT" : employee.average_performance >= 3.5 && employee.average_performance <= 4 ? "OUTSTANDING" : "--"
                                                )} font-semibold`}>
                                                {(employee.average_performance < 0.5 && employee.supervisor_approval_report === "approved") ? "UNACCEPTABLE" : employee.average_performance >= 0.5 && employee.average_performance < 1.5 ? "ACCEPTABLE" : employee.average_performance >= 1.5 && employee.average_performance < 2.5 ? "GOOD" : employee.average_performance >= 2.5 && employee.average_performance < 3.5 ? "EXCELLENT" : employee.average_performance >= 3.5 && employee.average_performance <= 4 ? "OUTSTANDING" : "--"}
                                            </span>
                                                {/*<IoAnalyticsOutline className={`w-5 h-5`}/>*/}
                                                {/*<p className={`text-sm font-bold`}>Avg: {employee.average_performance.toFixed(1)}</p>*/}
                                            </span>
                                    </div>
                                ))
                            }

                        </div>
                    ):(
                        <div className={`w-full h-64 flex flex-row justify-start bg-white shadow-sm shadow-500/40 rounded-md py-6 px-5 items-end my-28`}>
                            <div className={`w-full h-full flex flex-row items-center justify-center`}>
                                <p className={`text-center text-wrap text-sm font-semibold text-gray-500`}>No Depatment Selected!</p>
                            </div>
                        </div>
                    )
                }


            </div>

        </div>
    );
};

export default AnalyticPage;