import axios from "axios";
import {API_URL, X_API_KEY} from "../utils/api";
import {EmployeeDB} from "./employee";
import {client} from "../utils/axios";

export type ObjectiveData = {
    id: number;
    name: string;
    is_complete: boolean;
    employee_id: {
        Int64: number;
        Valid: boolean
    };
    Employee: EmployeeDB;
    dead_line: string;
    created_at: string;
};

export type ObjectiveInput = {
    name: string;
    employee_id?: number;
    dead_line: Date;
}

export const getObjectives = async () => {
    try {
        return await client
            .get<ObjectiveData[]>("objectives")
            .then(res => res.data);
    } catch (err) {}
};


export const createObjectiveDB = async (input: ObjectiveInput) => {
    try {
        return await client
            .post<ObjectiveData>("objectives", input)
            .then(res => res.data);
    } catch (err) {}
};
